import React, { useState } from "react"
import { graphql, Link } from "gatsby"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons/faArrowRightLong"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import { Text, Image } from "../components/Core"
import { Section, Container } from "../components/Containers"
import { ImageText, FullImageText } from "../components/ImageText"
import TheaterVideo from "../components/Theater/TheaterVideo"
import { Gallery } from "../components/Gallery"

const Homepage = ({ pageContext, data }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { metaTitle, metaDescription, socialImage, schemaPage } = pageContext
  const {
    hero,
    heading,
    imageText,
    wantToCruise,
    visitorOffice,
    watchImageText,
    learnMore
  } = data.allUniquePagesJson.nodes[0]

  return (
    <Layout className="homepage">
      <SEO
        title={metaTitle}
        description={metaDescription}
        socialImage={socialImage}
        schemaPage={schemaPage}
        preload={[
          {
            as: "image",
            href: `https://res.cloudinary.com/nuvolum/image/upload/c_scale,f_auto,q_99,w_400/v1/${hero.image}.jpg`
          }
        ]}
      />

      <Image useAR publicId={hero.image} />

      <Section>
        <Container small>
          <Text as="h1" text={heading} />
        </Container>
      </Section>

      <Section>
        <Container medium>
          <ImageText
            reverse={imageText.reverse}
            text={imageText.text}
            image={imageText.image}
          />
        </Container>
      </Section>

      <FullImageText image={watchImageText.image}>
        <div>
          <Text
            className="mb-3 has-text-centered-mobile"
            as="h3"
            text={watchImageText.text}
          />
          <TheaterVideo
            videoUrl={`https://www.youtube.com/watch?v=${watchImageText.buttons[0].button.youtube}`}
            controls
            playing
            iconButton
            language={"en"}
          />
        </div>
      </FullImageText>

      <Section className="homepage__cruise">
        {/* <Container maxWidth={530} xsmall className="has-text-centered">
          <Text as="h3" text={wantToCruise.text} />
          <Link to={wantToCruise.buttons[0].button.href}>
            {wantToCruise.buttons[0].button.buttonText}
          </Link>
        </Container> */}
        <Container xsmall>
          <Section smallSpacing colorBack>
            <Container>
              <Text text={visitorOffice} />
            </Container>
          </Section>
        </Container>
        <Container medium>
          <Image publicId={wantToCruise.image} />
        </Container>
      </Section>

      <Section className="homepage__learn-more">
        <Text
          className="has-text-centered mb-4"
          as="h3"
          text={learnMore.heading}
        />
        <Container medium>
          <div className="homepage__learn-more-cards">
            {learnMore.blocks.map(card => {
              return (
                <div key={card.heading} className="homepage__learn-more-card">
                  <Text as="h5" text={card.heading} />
                  <Text as="p" text={card.blurb} />
                  {card.image && (
                    <Image className="mt-2" publicId={card.image} />
                  )}
                  {card.link.href !== "{GALLERY}" ? (
                    <Link
                      className="mt-3 arrow-link"
                      to={card.link.href}
                      title={card.link.altTitle}>
                      {card.link.name}
                      <FontAwesomeIcon icon={faArrowRightLong} />
                    </Link>
                  ) : (
                    <div
                      tabIndex="0"
                      className="mt-3 arrow-link"
                      role="button"
                      aria-label="open image gallery"
                      onClick={() => setIsModalOpen(true)}>
                      View Gallery <FontAwesomeIcon icon={faArrowRightLong} />
                    </div>
                  )}
                </div>
              )
            })}
          </div>
        </Container>
      </Section>

      <Gallery
        setIsOpen={setIsModalOpen}
        isOpen={isModalOpen}
        images={learnMore.gallery}
      />
    </Layout>
  )
}

export const homepageQuery = graphql`
  query homepagequery {
    allUniquePagesJson(filter: { title: { eq: "/" } }) {
      nodes {
        hero {
          image
        }
        heading
        imageText {
          reverse
          image
          text
        }
        watchImageText {
          image
          text
          buttons {
            button {
              youtube
            }
          }
        }
        wantToCruise {
          image
          text
          buttons {
            button {
              buttonText
              href
              destination
              appearance
            }
          }
        }
        visitorOffice
        learnMore {
          heading
          blocks {
            heading
            blurb
            image
            link {
              name
              altTitle
              href
            }
          }
          gallery
        }
      }
    }
  }
`

export default Homepage
